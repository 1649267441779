import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import { Container, Row, Col } from 'reactstrap';
import SEO from '../components/seo';
import Img from 'gatsby-image';
import GoogleMap from '../components/googleMap';
import PortfolioGrid from '../components/portfolio/portfolio-grid';
import SideArrow from '../components/icons/arrows/SideArrow';
import { Parallax, ParallaxLayer } from 'react-spring/renderprops-addons';

const Portfolio = ({ data }) => {
    // eslint-disable-next-line
    let parallax;
    return (
        <Parallax ref={(ref) => (parallax = ref)}>
            <Layout>
                <SEO
                    title={data.wpgraphql.page.title}
                    description={data.wpgraphql.page.acf_page_meta.metadescription}
                />

                <header className="header--fullwidth header--darkbg">
                    {data.wpgraphql.page.featuredImage != null && (
                        <Img
                            className="header__image"
                            fluid={
                                data.wpgraphql.page.featuredImage.node.imageFile.childImageSharp
                                    .fluid
                            }
                        />
                    )}
                    <Container>
                        <Row>
                            <Col xs={{ offset: 1 }} md={{ size: 8 }}>
                                <h1 className="heading1">
                                    Over $3.5B in <span>Development and Acquisitions Volume.</span>
                                </h1>
                            </Col>
                        </Row>
                    </Container>
                </header>

                <Container>
                    <Row>
                        <Col md={{ size: 10, offset: 4 }} lg={{ size: 7, offset: 7 }}>
                            <p className="hero-text body-copy--large">
                                {' '}
                                {data.wpgraphql.page.acf_portfolio_fields.heroDescription}
                            </p>
                        </Col>
                    </Row>
                </Container>

                <section className="section__recent-aquisitions bg-light">
                    <Container>
                        <Row>
                            <Col md={{ size: 6, offset: 1 }}>
                                <h2 className="heading2">
                                    {data.wpgraphql.page.acf_portfolio_fields.subhead}
                                </h2>
                                <p className="body-copy">
                                    {data.wpgraphql.page.acf_portfolio_fields.subheadDescription}
                                </p>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={{ size: 13, offset: 1 }}>
                                <PortfolioGrid />
                            </Col>
                        </Row>
                    </Container>
                    <ParallaxLayer
                        offset={-1.1}
                        speed={0.15}
                        className="parallax__arrow-container d-none d-md-block"
                    >
                        <SideArrow className="parallax__arrow-right" />
                    </ParallaxLayer>
                </section>
                <Container>
                    <Row>
                        <Col md={{ size: 13, offset: 1 }}>
                            <div className="section__property-map mapcontainer">
                                <GoogleMap />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Layout>
        </Parallax>
    );
};

export default Portfolio;

export const query = graphql`
    query {
        wpgraphql {
            page(id: "cGFnZToxMA==") {
                title
                acf_page_meta {
                    metadescription
                }
                acf_portfolio_fields {
                    heroHead
                    heroDescription
                    subhead
                    subheadDescription
                }
                featuredImage {
                    node {
                        sourceUrl
                        imageFile {
                            childImageSharp {
                                fluid(maxWidth: 1600, quality: 90) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
